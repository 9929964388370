<template>
  <div class="course-edit"><!-- v-loading.fullscreen.lock="pageLoading"-->
    <el-card class="box-card detailHeaderBox">
      <div class="course-detail-header content" :style="{ 'background-image': 'url('+defaultHeaderBg+')', 'background-repeat': 'no-repeat' , 'background-size': '100% 6.25vw' }">
        <div class="header-left">
          <div class="img-div">
            <img :src="courseContentInfo.coverImg ? courseContentInfo.coverImg : defaultHeaderImg" class="course-img"/>
          </div>
          <div class="course-info">
            <div class="title">
              <div class="icon-div">{{ courseContentInfo.status == '0' ? '未发布' : courseContentInfo.status == '1' ? '已上架' : courseContentInfo.status == '2' ? '已下架' : '' }}</div>
              <span>{{ courseContentInfo.courseThemeName }}</span>
            </div>
            <el-tooltip class="item" effect="light" popper-class="customPopper" :content="courseContentInfo.description" placement="top" v-if="courseContentInfo.description">
              <div class="description" v-if="courseContentInfo.description">{{ courseContentInfo.description }}</div>
            </el-tooltip>
            <div class="description" v-if="!courseContentInfo.description"></div>
            <div class="course-info-bottom">
              <div class="bottom-item">
                <div class="label">老师：</div>
                <div class="text">{{ courseContentInfo.userName }}</div>
                <div class="line"></div>
              </div>
              <div class="bottom-item">
                <div class="label long">创建时间：</div>
                <div class="text long">{{ courseContentInfo.createTime }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="header-right"></div>
      </div>
      <div class="course-detail-title catalog">
        <div class="text active">
          <span>【课程内容】目录</span>
          <div class="line"></div>
        </div>
      </div>
    </el-card>
    <div class="tab-info">
      <div class="catalog-panel-data">
        <!-- 表单 -->
        <el-form onsubmit="return false" :inline="true" style="padding: 0 1.04167vw">
          <el-form-item style="float: right">
            <el-button type="primary" @click="handleAdd">添加</el-button>
          </el-form-item><!--v-if="permissions.includes('sys_dict_add')"-->
        </el-form>
        <el-table :data="tableData" style="width: calc(100% - 1.66667vw); margin: 0 1.04167vw 1.04167vw 1.04167vw" row-key="id" border default-expand-all :tree-props="{ children: 'children', hasChildren: 'hasChildren', }">
          <el-table-column type="index" label="序号" width="100" align="center"></el-table-column>
          <el-table-column prop="catalogueName" label="目录名称" width="500"></el-table-column>
          <el-table-column prop="sort" label="排序" width="200" align="center">
            <template slot-scope="scope">
              <el-button type="text" v-if="scope.row.sortIndex != 0" class="button" @click="handleItem('up', scope.row)">上移</el-button>
              <el-button type="text" v-if="scope.row.parentCatalogueId === null ? scope.row.sortIndex != tableData.length - 1 : scope.row.sortIndex != scope.row.childrenLength - 1" class="button" @click="handleItem('down', scope.row)">下移</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="userName" label="创建人" width="200" align="center"></el-table-column>
          <el-table-column prop="createTime" label="创建时间" width="250" align="center"></el-table-column>
          <el-table-column fixed="right" label="操作" width="300">
            <template slot-scope="scope">
              <el-button type="text" class="button" @click="handleItem('contentSetting', scope.row)">内容</el-button>
              <el-divider direction="vertical"></el-divider>
              <el-button type="text" class="button" @click="handleItem('targetSetting', scope.row)">目标</el-button>
              <el-divider direction="vertical" v-if="scope.row.levelType != 3"></el-divider>
              <el-button type="text" class="button" v-if="scope.row.levelType != 3" @click="handleItem('add', scope.row)">添加</el-button>
              <el-divider direction="vertical"></el-divider>
              <el-button type="text" class="button" @click="handleItem('edit', scope.row)">编辑</el-button>
              <el-divider direction="vertical"></el-divider>
              <el-button type="text" class="button" @click="handleItem('del', scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog :title="courseContentTitle" :visible.sync="courseContentVisible" :append-to-body="true" :destroy-on-close="true" @before-close="handleClose" @close="handleClose" width="50%">
      <el-form :model="courseContentCatalogForm" ref="courseContentCatalogForm" :rules="rules" label-width="6.25vw">
        <el-row>
          <el-col :span="24">
            <el-form-item class="form-choose-item" label="名称：" prop="catalogueName">
              <el-input class="form-input" v-model="courseContentCatalogForm.catalogueName" placeholder="请输入名称" :maxlength="50"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item" label="是否必填：">
              <el-switch v-model="courseContentCatalogForm.isRequired">
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item" label="家长展示：">
              <el-switch v-model="courseContentCatalogForm.isOpen"> </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item class="form-choose-item" label="集体活动：">
              <el-switch v-model="courseContentCatalogForm.isTeam"> </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button :loading="buttonloading" @click="handleClose">取 消</el-button>
        <el-button :loading="buttonloading" type="primary" @click="handleSubmit">保 存</el-button>
      </div>
    </el-dialog>
    <catalog-setting :catalogSettingDialogVisible.sync="contentSettingDialogVisible" :catalogSettingDialogHandleType="handleType" :catalogSettingDialogType="contentSettingType" :courseId="courseContentCatalogId" :formData="contentSettingData" @refreshPage="getContentInfo"></catalog-setting>
    <target-setting :targetSettingDialogVisible.sync="targetSettingDialogVisible" :targetSettingDialogHandleType="targetSettingDialogHandleType" :formData="targetSettingData" :courseContentCatalogId="courseContentCatalogId" @refreshPage="getTargetInfo"></target-setting>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import CatalogSetting from "@/components/catalogSetting/index.vue";
import TargetSetting from "@/components/targetSetting/index.vue";
import detailTeacherIcon from "@/assets/img/detail-teacher-icon.png";
import detailTimeIcon from "@/assets/img/detail-time-icon.png";
import defaultImg from "@/assets/img/default-img.png";
import defaultHeaderImg from "@/assets/img/default-header-img.png";
import defaultHeaderBg from "@/assets/img/detail-header-bg.png";
import { noSymbol } from "@/utils/validate.js";
export default {
  name: "courseCatalogSetting",
  components: { CatalogSetting, TargetSetting, },
  data() {
    let checkName = (rule, value, callback) => {
      if (value === "") {
        return callback();
      }
      if (value !== "" && !noSymbol(value)) {
        callback(new Error("只支持汉字、数字、字母组合"));
      } else {
        callback();
      }
    };
    return {
      activeTab: "courseIntroduce", //介绍和内容切换
      tableData: [], //目录信息
      courseContentInfo: {
        coverImg: "",
        courseThemeName: "",
        description: "",
        status: "",
        userName: "",
        createTime: "",
      }, //课程内容信息
      detailTeacherIcon, //教师icon
      detailTimeIcon, //时间icon
      defaultImg, //默认图片
      defaultHeaderImg, //头部信息默认图片
      defaultHeaderBg, //头部背景图

      courseContentTitle: "", //课程内容目录信息弹窗标题
      courseContentVisible: false, //课程内容目录信息弹窗显隐标识
      courseContentCatalogForm: {
        catalogueName: "",
        isRequired: true,
        isOpen: false,
        isTeam: false
      }, //课程内容目录信息form
      rules: {
        catalogueName: [
          { required: true, message: "请输入名称", trigger: "blur",},
          { min: 2, message: "名称长度不能小于2", trigger: "blur" },
        ],
      },
      courseContentCatalogId: null, //课程内容目录id
      parentCatalogId: null, //课程目录父级id
      courseContentId: null, //课程内容id

      contentSettingDialogVisible: false, //目录内容设置弹窗显隐标识
      contentSettingType: "", //目录内容设置弹窗类型
      contentSettingData: {}, //目录内容设置数据

      buttonloading: false, //弹框按钮loading

      targetSettingDialogVisible: false, //指标设置弹窗显隐标识
      targetSettingDialogHandleType: "", //指标设置弹窗类型
      targetSettingData: {}, //指标设置弹窗数据

      handleType: "", //处理方式
      newCourseContentCatalogId: null, //新增目录时的id
      pageLoading: null,// 页面loading
    };
  },
  computed: {
    ...mapGetters(["permissions"]),
  },
  created() {
    this.courseContentId = this.$route.query.id;
    this.pageLoading = this.$loading({
      lock: true,
      fullscreen: true,
      text: '加载中',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });
    this.getData();
    this.getCatalogList();
  },
  methods: {
    //获取课程详情
    getData() {
      this.$api.getCourseContentInfo(this.courseContentId).then((res) => {
        if (res.data.code === 0) {
          this.courseContentInfo = { ...res.data.data };
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //获取目录列表
    getCatalogList() {
      this.$api.getCourseContentCataLogList({ courseThemeId: this.courseContentId }).then((res) => {
        if (res.data.code === 0) {
          res.data.data.map((item, i) => {
            item.levelType = 1;
            item.sortIndex = i;
            item.parentIndex = null
            item.sendIndex = null
            item.firstIndex = null
            if (item.children) {
              item.children.map((info, j) => {
                info.levelType = 2;
                info.sortIndex = j;
                info.sendIndex = null
                info.firstIndex = i
                info.childrenLength = item.children ? item.children.length : 0;
                if (info.children) {
                  info.children.map((result, g) => {
                    result.levelType = 3;
                    result.sortIndex = g;
                    result.sendIndex = j
                    result.firstIndex = i
                    result.childrenLength = info.children ? info.children.length : 0;
                  });
                }
              });
            }
          });
          this.tableData = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
        this.pageLoading.close()
      }).catch(() => {
        this.pageLoading.close()
      });
    },
    //添加目录信息
    handleAdd() {
      this.handleType = "add";
      this.courseContentVisible = true;
      this.$nextTick(() => {
        this.courseContentTitle = "添加";
      });
    },
    //操作事件
    handleItem(type, row) {
      switch (type) {
        case "contentSetting":
          this.handleType = "edit";
          this.contentSettingDialogVisible = true;
          this.$nextTick(() => {
            this.courseContentCatalogId = row.id;
            this.contentSettingType = "content";
            // this.courseContentId = ""
            // this.contentSettingData = {}
          });
          break;
        case "edit":
          this.handleType = "edit";
          this.courseContentVisible = true;
          this.$nextTick(() => {
            this.courseContentTitle = "编辑";
            this.courseContentCatalogId = row.id;
            this.parentCatalogId = row.parentCatalogueId;
            this.courseContentCatalogForm.catalogueName = row.catalogueName;
            this.courseContentCatalogForm.isRequired = row.isRequired == "1" ? true : false;
            this.courseContentCatalogForm.isOpen = row.isOpen == "1" ? true : false;
            this.courseContentCatalogForm.isTeam = row.isTeam == "1" ? true : false;
          });
          break;
        case "add":
          this.handleType = "add";
          this.courseContentVisible = true;
          this.$nextTick(() => {
            this.courseContentTitle = "添加";
            this.parentCatalogId = row.id;
            // this.courseContentId = ""
            // this.courseContentCatalogForm = {}
          });
          break;
        case "up":
          this.moveCatalog("up", row);
          break;
        case "down":
          this.moveCatalog("down", row);
          break;
        case "del":
          this.deleteCourseContent(row.id);
          break;
        case "targetSetting":
          this.handleType = "edit";
          this.targetSettingDialogVisible = true;
          this.$nextTick(() => {
            this.courseContentCatalogId = row.id;
            this.targetSettingDialogHandleType = "edit";
            // this.courseContentId = ""
            // this.targetSettingData = {}
          });
          break;
      }
    },
    //移动事件
    moveCatalog(type, row) {
      let itemIndex = null;
      let itemList = null;
      if (row.parentCatalogueId !== null) {
        let itemData = null
        if(row.levelType == '2'){
          itemData = this.tableData.find((item) => { return item.id == row.parentCatalogueId })
        } else if(row.levelType == '3'){
          itemData = this.tableData[row.firstIndex].children.find((item) => { return item.id == row.parentCatalogueId })
        }
        let treeList = itemData.children
        itemList = JSON.parse(JSON.stringify(treeList));
        itemIndex = itemList.findIndex((info) => { return info.id == row.id; });
      } else {
        itemList = JSON.parse(JSON.stringify(this.tableData));
        itemIndex = itemList.findIndex((item) => { return item.id == row.id; });
      }
      let params = {
        id: row.id,
        sort: row.sort,
      };
      if (type == "up") {
        params.moveId = itemList[itemIndex - 1].id;
        params.moveSort = itemList[itemIndex - 1].sort;
      } else if (type == "down") {
        params.moveId = itemList[itemIndex + 1].id;
        params.moveSort = itemList[itemIndex + 1].sort;
      }
      this.$api.courseContentCataLogHandle("/api/educoursethemecatalogue/move", params, "move").then((res) => {
        if (res.data.code === 0) {
          this.$message({ type: "success", message: "移动成功!", duration: 2000, onClose: () => {
            this.pageLoading = this.$loading({
              lock: true,
              fullscreen: true,
              text: '加载中',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            this.getCatalogList();
          }, });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //删除
    deleteCourseContent(id) {
      //"确定删除该层目录, 是否继续?"
      const h = this.$createElement;
      this.$confirm(
        h("p", null, [
          h("span", { style: "color: rgba(0, 0, 0, 0.85);font-size: 16px;font-weight: 500;", }, "确定要删除该层目录？"),
          h("div", { style: "color: rgba(0, 0, 0, 0.65);font-size: 14px;margin-top: 10px", }, "是否确定删除该层目录，删除后，将无法恢复。"),
        ]), "提示", { confirmButtonText: "确定", cancelButtonText: "取消", iconClass: "el-icon-question", type: "warning", customClass: "warningMessage", }
      ).then(() => {
        this.$api.courseContentCataLogHandle("/api/educoursethemecatalogue/del", { id: id, delFlag: "1" }, "del").then((res) => {
          if (res.data.code === 0) {
            this.$message({ type: "success", message: "删除成功!", duration: 2000, onClose: () => {
              this.pageLoading = this.$loading({
                lock: true,
                fullscreen: true,
                text: '加载中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
              });
              this.getCatalogList();
            }, });
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }).catch(() => {});
    },
    //弹框关闭
    handleClose() {
      this.courseContentVisible = false;
      this.$nextTick(() => {
        this.courseContentTitle = "";
        this.courseContentCatalogForm = {
          catalogueName: "",
          isRequired: true,
          isOpen: false,
          isTeam: false
        };
        this.$refs.courseContentCatalogForm.clearValidate();
        // if (this.handleType == "edit") {
          this.parentCatalogId = null;
          this.courseContentCatalogId = null;
          this.handleType == "";
        // }
      });
    },
    //弹窗完成
    handleSubmit() {
      this.$refs.courseContentCatalogForm.validate((valid) => {
        if (valid) {
          this.buttonloading = true;
          let params = {
            catalogueName: this.courseContentCatalogForm.catalogueName,
            courseThemeId: this.courseContentId,
            isRequired: this.courseContentCatalogForm.isRequired ? "1" : "0",
            isOpen: this.courseContentCatalogForm.isOpen ? "1" : "0",
            parentCatalogueId: this.parentCatalogId,
            isTeam: this.courseContentCatalogForm.isTeam ? "1" : "0"
          };
          let url = "";
          if (this.courseContentCatalogId !== null) {
            params.id = this.courseContentCatalogId;
            url = "/api/educoursethemecatalogue";
          } else {
            url = "/api/educoursethemecatalogue/save";
          }
          this.$api.courseContentCataLogHandle(url, params, this.handleType).then((res) => {
            if (res.data.code === 0) {
              this.$message({ message: "保存成功", type: "success", duration: 2000, onClose: () => {
                this.buttonloading = false;
                this.newCourseContentCatalogId = res.data.data;
                // if (this.handleType == "add") {
                //   this.contentSettingDialogVisible = true;
                //   this.$nextTick(() => {
                //     this.courseContentCatalogId = res.data.data;
                //     this.contentSettingType = "content";
                //     // this.contentSettingData = {}
                //   });
                // } else {
                  this.pageLoading = this.$loading({
                    lock: true,
                    fullscreen: true,
                    text: '加载中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                  });
                  this.getCatalogList();
                // }
                this.handleClose();
              }, });
            } else {
              this.buttonloading = false;
              this.$message.error(res.data.msg);
            }
          }).catch(() => {
            this.buttonloading = false;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //获取内容设置编辑操作
    getContentInfo(data) {
      if (this.handleType == "add" && data) {
        this.targetSettingDialogVisible = true;
        this.$nextTick(() => {
          this.targetSettingDialogHandleType = "add";
          this.courseContentCatalogId = this.newCourseContentCatalogId;
          this.contentSettingDialogVisible = false;
          this.$nextTick(() => {
            this.contentSettingType = "";
            this.contentSettingData = {};
          });
        });
      } else if (
        this.handleType == "edit" ||
        (this.handleType == "add" && !data)
      ) {
        this.contentSettingDialogVisible = false;
        this.$nextTick(() => {
          this.contentSettingType = "";
          this.contentSettingData = {};
          if(!this.targetSettingDialogVisible){
            this.courseContentCatalogId = null;
            this.parentCatalogId = null;
            this.handleType == "";
            this.pageLoading = this.$loading({
              lock: true,
              fullscreen: true,
              text: '加载中',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            this.getCatalogList();
          }
        });
      }
    },
    //获取指标设置操作
    getTargetInfo(data) {
      this.targetSettingDialogVisible = false;
      this.$nextTick(() => {
        this.targetSettingDialogHandleType = "";
        // this.courseContentId = "";
        this.targetSettingData = {};
        this.parentCatalogId = null;
        this.courseContentCatalogId = null;
        this.newCourseContentCatalogId = null;
        this.handleType == "";
        this.pageLoading = this.$loading({
          lock: true,
          fullscreen: true,
          text: '加载中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.getCatalogList();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
//tab样式
/deep/.el-tabs__nav-wrap {
  padding: 0 20px;
  height: 60px;
}

/deep/.el-tabs__nav {
  font-size: 20px;
  height: 60px;
  .el-tabs__item {
    height: 60px;
    line-height: 60px;
    font-size: 20px;
  }
}
</style>